import React from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import {
  navbar,
  banner,
  stickyBlocks,
  contactUsCard,
  navBarContact,
  whatsAppContact,
  whatsAappUrlForPage,
  announcementStrip,
} from "utils/renderBlocks";
import FontLoad from "components/FontLoad";
import { GuestOptionsBar } from "components/GuestOptionsBar";
import { WhatsAppFloat } from "components/Design/FloatingButton";
import RoomSelectionSearchDrawer from "components/Design/Drawer/RoomSelectionSearchDrawer";
import { RenderBlock } from "components/RenderBlock";
import { FOOTER, NAVBAR } from "store/constants/blockThemes";
import { OfferLayout } from "components/OfferLayout";
import { StickyCta } from "components/StickyCta";
import { FooterBlock } from "components/Blocks/FooterBlock";
import "components/Layout/style.less";
import { transformObject } from "utils/transformv4";
import { getHotel } from "utils/getHotel";
import { AYANA_KOMODO_ID, AYANA_LAKODIA_ID } from "store/constants/hotel";

const OfferPage = ({ data }) => {
  const {
    allNavigationMenuJson: { nodes: navigations },
    cms,
    allSocialMediaJson: { nodes: allSocialMedia },
  } = data;

  const {
    hotelsByLocale,
    destinationImagesSections,
    supportDrawers,
    lvxLogo: [lvxLogo],
    page: {
      blocks,
      offers: [offer],
    },
  } = transformObject(cms);

  const { language } = useI18next();

  const navbarBlock = navbar(blocks);
  const bannerBlock = banner(blocks);
  const stickyBlock = stickyBlocks(blocks);
  const contactUsBlock = contactUsCard(blocks);
  const announcementStripBlock = announcementStrip(blocks);
  const whatsAppContactBlock = whatsAppContact(blocks);
  const destination = navbarBlock?.destination;
  const navbarContactBlock = navBarContact(blocks);
  const hotel = getHotel(navbarBlock, hotelsByLocale);

  const formattedWhatsAppUrl = whatsAappUrlForPage({
    hotel,
    destination: navbarBlock.destination,
    whatsAppContactBlock,
  });

  const statusWaDisbled = [AYANA_KOMODO_ID, AYANA_LAKODIA_ID].find(
    (hotelId) => hotelId === navbarBlock.hotel?.id
  );

  return (
    <>
      {(language === "zh" || language === "ja") && <FontLoad />}

      <div
        className={cx("antialiased", {
          /* helps in wrapping words at spaces for Japanese language */
          "font-ja wrap-word": language === "ja",
          "font-zh": language === "zh",
          "font-ko": language === "ko",
        })}>
        <div className="hidden lg:block lg:sticky lg:top-0 lg:z-100">
          <GuestOptionsBar
            destinationCards={destinationImagesSections[0].images}
            hotel={hotelsByLocale[0]}
            cartCount={0}
            navigationHotel={hotelsByLocale[0].name}
          />
        </div>
        <div className="antialiased">
          <RenderBlock
            key={`navbar-${navbarBlock.id}`}
            block={{
              ...navbarBlock,
              allHotels: hotelsByLocale,
              navigations,
              destinationImagesSections,
              supportDrawers,
              theme: NAVBAR,
              bookingCode: offer.booking_code?.booking_code,
              formattedWhatsAppUrl,
              navbarContactBlock,
              packageCode: offer.package_code,
              isPackage: offer.is_package,
              minimumStay: offer.minimum_stay,
              minLeadDay: offer.min_lead_day,
              announcementStrip: announcementStripBlock,
            }}
          />
          {bannerBlock && (
            <RenderBlock
              key={`${bannerBlock.__typename}:${bannerBlock.id}`}
              block={bannerBlock}
            />
          )}
          <div className="overflow-x-hidden">
            <OfferLayout offer={offer} blocks={blocks} />
          </div>

          <FooterBlock
            {...{
              ...navbarBlock,
              allHotels: hotelsByLocale,
              allSocialMedia,
              navigations,
              destinationImagesSections,
              supportDrawers,
              theme: FOOTER,
              lvxLogo,
            }}
          />
          {!Boolean(statusWaDisbled) && (
            <WhatsAppFloat link={formattedWhatsAppUrl} />
          )}
          <RoomSelectionSearchDrawer
            bookingCode={offer.booking_code?.booking_code}
            hotelId={navbarBlock.hotel?.id}
            packageCode={offer.package_code}
            minimumStay={offer.minimum_stay}
            isPackage={offer.is_package}
            location={destination}
            minLeadDay={offer.min_lead_day}
            cta={(onClick, sticky) => (
              <div
                className={cx(
                  "fixed lg:hidden bottom-0 w-full z-20 transition-all duration-500",
                  {
                    "opacity-0 invisible": !sticky,
                    "opacity-100 visible": sticky,
                  }
                )}>
                <StickyCta
                  stickyBlock={stickyBlock}
                  contactUsBlock={contactUsBlock}
                  onClick={onClick}
                  destination={destination}
                  whatsAppUrl={formattedWhatsAppUrl}
                />
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default OfferPage;

export const query = graphql`
  query OfferTemplate($id: ID!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allNavigationMenuJson {
      ...LocalNavigation
    }

    allSocialMediaJson {
      nodes {
        hotel
        code
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }

    cms {
      page: pageByLocale(id: $id, language: $language) {
        data {
          id
          attributes {
            slug
            layout
            ...CmsPageDynamicBlocks_v4
            offers {
              data {
                id
                attributes {
                  booking_code {
                    data {
                      id
                      attributes {
                        booking_code
                      }
                    }
                  }
                  book_period {
                    id
                    start_date
                    end_date
                  }
                  stay_period {
                    id
                    start_date
                    end_date
                  }
                  blackout_dates {
                    id
                    start_date
                    end_date
                  }
                  minimum_stay
                  min_lead_day
                  is_package
                  package_code
                  hotels {
                    data {
                      id
                      attributes {
                        hotel_code
                        location
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      lvxLogo: images(filters: { name: { eq: "LVX_Preferred_Logo" } }) {
        data {
          attributes {
            desktop {
              ...CmsUploadFileEntityResponse
            }
            mobile {
              ...CmsUploadFileEntityResponse
            }
          }
        }
      }
      hotelsByLocale(language: $language, sort: "id:DESC") {
        data {
          id
          attributes {
            phone
            location
            title
            name
            whatsapp_contact
            custom_whatsapp_message
            ...CmsHotelLogo_v4
            destinations {
              ...CmsDestinations
            }
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            activities {
              ...CmsActivities_v4
            }
          }
        }
      }
      destinationImagesSections: sections(
        filters: { id: { in: [236, 84, 140, 292] } }
      ) {
        data {
          id
          attributes {
            ...CmsSection_v4
            images {
              ...CmsImageFullWidth_v4
            }
          }
        }
      }
      supportDrawers: sections(filters: { id: { in: [250, 237, 154, 293] } }) {
        data {
          id
          attributes {
            ...CmsSection_v4
            images(sort: "sequence") {
              ...CmsImageFullWidth_v4
            }
          }
        }
      }
    }
  }
`;
